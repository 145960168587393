/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Recordando la ", React.createElement(_components.a, {
    href: "https://www.ai-class.com/"
  }, "clase sobre inteligencia artificial"), " en la que participé el año pasado me ha dado por pensar en lo necesaria que es la información para el buen funcionamiento de los sistemas de inteligencia artificial. En este campo las macroempresas de la información juegan con ventaja; Google, facebook y compañía van un paso por delante puesto que les regalamos nuestros datos. Está muy claro por ejemplo el caso de ReCaptcha, el sistema adquirido por google para distinguir entre maquinas y personas, que tiene la ventaja de ayudar a Google en el desarrollo de un ", React.createElement(_components.a, {
    href: "https://es.wikipedia.org/wiki/Reconocimiento_%C3%B3ptico_de_caracteres"
  }, "OCR"), " que descifre los libros que está digitalizando en Google Books. Google ha utilizado su posición en el mercado para prácticamente imponer a toda la web ReCaptcha."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/09172009-02.jpg",
    alt: "ReCaptcha",
    title: "ReCaptcha: Matando dos pájaros de un tiro y ya de paso a la competencia."
  })), "\n", React.createElement(_components.p, null, "Gracias ", React.createElement(_components.a, {
    href: "http://www.versvs.net/anotacion/infraestructura-como-ventaja-competitiva"
  }, "su gigantesca infraestructura"), " y a su control de la información Google va a desbancar a cualquier empresa que se pueda dedicar al OCR, algo parecido podriamos decir de Facebook y el reconocimiento de caras."), "\n", React.createElement(_components.h4, null, "¿Algún motivo de esperanza o nos vendemos ya a Google y compañía?"), "\n", React.createElement(_components.p, null, "Hay una forma de contraatacar o mejor dicho atacar donde Google no puede llegar, la clave está en la ", React.createElement(_components.a, {
    href: "http://personotecnia.wordpress.com/"
  }, "personotécnia"), ", crear sistemas de inteligencia artificial orientados a comunidades y personas. Pondré otro ejemplo:"), "\n", React.createElement(_components.p, null, "Intentamos crear un programa de predicción de escritura, desde la llegada de los smartphones hay muchos su principio de funcionamiento es muy básico, conociendo el idioma en que escribimos intentan determinar que palabra vamos a escribir con sólo las primeras letras. Google en su buscador desde hace tiempo que tiene algo parecido utiliza las busquedas más repetidas. Una forma de hacer esto mas eficiente sería utilizar las palabras que más utilizas para con esta ", React.createElement(_components.a, {
    href: "http://en.wikipedia.org/wiki/Bag_of_words"
  }, "bolsa de palabras"), " desarrollar tu aplicación, incluso se podría mejorar utilizando como segunda fuente de palabras las que más utilice tu comunidad obteniendo un predictor de palabras se adelantara a lo que escribes puesto que lo que escribes está claramente relacionado con lo que lees de los que más cerca de ti están."), "\n", React.createElement(_components.p, null, "Personalizando los sistemas de inteligencia artificial es posible obtener un comportamiento no más inteligente pero más útil para la persona que lo utiliza y ahí está el futuro de las pequeñas empresas que se quieran dedidar a la inteligencia artificial."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
